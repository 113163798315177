/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"kování"} description={"kovani"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"x59hobd2kyt"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Dveřní kování"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Interiérová kování"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":534}} content={"Interiérová kování mají krom své funkce také estetickou vlastnost. Kování můžou být buďto štítová, nebo rozetová. U kování štítových je důležitým parametrem rozteč, ta se určuje podle zámku a je 72, 90, nebo 92. Kování mohou být ve variantě klika klika, nebo klika koule, různých materiálů, povrchových úprav a barev, na cylindrickou vložku, nebo tvarový klíč.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":399}} src={"https://cdn.swbpg.com/t/19567/12ab335c52884c9dafac14f5a90602bf_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/12ab335c52884c9dafac14f5a90602bf_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/12ab335c52884c9dafac14f5a90602bf_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/12ab335c52884c9dafac14f5a90602bf_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9ou748ngnd7"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"Bezpečnostní kování"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":534}} content={"Bezpečnostní kování mají především plnit funkci jak už z názvu vyplývá zajistit bezpečnost vašeho majetku. Kování mohou být z hliníkových slitin, nerezy nebo jiných kovů. Opět je důležitá rozteč zámku 72, 90, 92. Chrání cylindrickou vložku proti zlomení a v případě překrytí i proti odvrtání. Opět mohou být v kombinaci klika klika, klika koule, nebo klika madlo. Máme na výběr různé barvy, povrchové úpravy a tvary od hranatých přes oblé až po ozdobná.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":399}} src={"https://cdn.swbpg.com/t/19567/14b127f8d34b410b94d0749c19ef236d_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/14b127f8d34b410b94d0749c19ef236d_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/14b127f8d34b410b94d0749c19ef236d_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/14b127f8d34b410b94d0749c19ef236d_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-2);\">Rádi vyhovíme Vašim přáním a požadavkům</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}